<template>
    <div class="text-center">
        <h1 class="mt-2 mb-2">{{ name_plan }}</h1>
        <b-row>
            <b-col sm="6">
                <b-card no-body>
                    <h1 class="mt-2 mb-2">América</h1>
                    <b-col md="8" class="text-left" v-for="country in america" :key="country.id">
                        <div @click="saveCountry(country)">
                            <input type="checkbox" name="nose" class="mr-1" :value="country.id" :checked="country.status">
                            <label>{{ country.name }}</label>
                        </div>
                    </b-col>
                </b-card>
            </b-col>
            <b-col sm="6">
                <b-card no-body>
                    <h1 class="mt-2 mb-2">África</h1>
                    <b-col md="8" class="text-left" v-for="country in africa" :key="country.id">
                        <div @click="saveCountry(country)">
                            <input type="checkbox" name="nose" class="mr-1" :value="country.id" :checked="country.status">
                            <label>{{ country.name }}</label>
                        </div>
                    </b-col>
                </b-card>
            </b-col>
            <b-col sm="4">
                <b-card no-body>
                    <h1 class="mt-2 mb-2">Oceanía</h1>
                    <b-col md="8" class="text-left" v-for="country in oceania" :key="country.id">
                        <div @click="saveCountry(country)">
                            <input type="checkbox" name="nose" class="mr-1" :value="country.id" :checked="country.status">
                            <label>{{ country.name }}</label>
                        </div>
                    </b-col>
                </b-card>
            </b-col>
            <b-col sm="4">
                <b-card no-body>
                    <h1 class="mt-2 mb-2">Europa</h1>
                    <b-col md="8" class="text-left" v-for="country in europa" :key="country.id">
                        <div @click="saveCountry(country)">
                            <input type="checkbox" name="nose" class="mr-1" :value="country.id" :checked="country.status">
                            <label>{{ country.name }}</label>
                        </div>
                    </b-col>
                </b-card>
            </b-col>
            <b-col sm="4">
                <b-card no-body>
                    <h1 class="mt-2 mb-2">Asia</h1>
                    <b-col md="8" class="text-left" v-for="country in asia" :key="country.id">
                        <div @click="saveCountry(country)">
                            <input type="checkbox" name="nose" class="mr-1" :value="country.id" :checked="country.status">
                            <label>{{ country.name }}</label>
                        </div>
                    </b-col>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import { BFormCheckbox, BRow, BCol, BCard, BCardBody } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BFormCheckbox,
        BRow,
        BCol,
        BCard,
        BCardBody
    },
    data() {
        return {
            africa: [],
            asia: [],
            america: [],
            europa: [],
            oceania: [],
            arrayCountry: [],
            name_plan: '',
            id: this.$route.params && this.$route.params.id,
            form: {
                id: this.$route.params && this.$route.params.id,
                country_id: '',
                status: '',
            }
        }
    },
    created() {
        this.getCountries()
        this.namePlan()
    },
    methods: {
        saveCountry(country) {
            this.form.country_id = country.id;
            this.form.status = country.status;
            let uri = null
            if (country.status == 0) {
                uri = '/plans/country-save'
            }
            if (country.status == 1) {
                uri = '/plans/country-delete'
            }
            this.$http.post(uri, this.form)
                .then(response => {
                    if (response.data.code == 200) {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: response.data.message,
                                icon: 'CoffeeIcon',
                                variant: 'success',
                            },
                        })
                    }
                    if (response.data.code == 500) {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: response.data.message,
                                icon: 'CoffeeIcon',
                                variant: 'danger',
                            },
                        })
                    }
                    this.getCountries()
                })
                .catch(error => {
                    console.log('error')
                })
        },
        getCountries() {
            this.$http.get('/plans/countries/' + this.id).then(response => {
                this.africa = response.data.africa
                this.asia = response.data.asia
                this.america = response.data.america
                this.europa = response.data.europa
                this.oceania = response.data.oceania
            })
        },
        namePlan() {
            this.$http.get('/plans/name-plan/' + this.id).then(response => {
                this.name_plan = response.data.plan
            })
        }
    }
}
</script>